import { Controller } from 'stimulus';

/**
 * Search contacts by name
 */
class ContactSearchController extends Controller {
  initialize() {
    this.options = [];
    this.selectedValues = this.element.value.split(' ');
    this.$element = $(this.element);

    if (this.element.dataset.contactType === 'provider_contact') {
      this.contactType = 'provider_contacts';
    } else {
      this.contactType = 'contacts';
    }

    this.select = this.init();
  }

  disconnect() {
    this.$element[0].selectize.destroy();
  }

  async init() {
    if (this.element.value !== '') {
      this.options = await this.fetchOptions();
    }

    this.$element.selectize({
      valueField: 'id',
      labelField: 'full_name',
      searchField: ['full_name', 'main_email'],
      items: this.selectedValues,
      options: this.options,
      maxItems: 20,
      create: false,
      render: {
        item: (item, escape) => {
          let email;
          if (item.main_email) {
            email = escape(item.main_email);
          } else {
            email = 'No main E-mail';
          }
          return `<div class="d-flex flex-column"><div>${escape(item.full_name)}</div><small>${email}</small></div>`;
        },
        option: (item, escape) => {
          let email;
          if (item.main_email) {
            email = escape(item.main_email);
          } else {
            email = 'No main E-mail';
          }
          return `<div class="list-group-item p-2 d-flex flex-column"><div>${escape(item.full_name)}</div><small>${email}</small></div>`;
        },
      },
      load: this.fetchData.bind(this),
    });
  }

  static async promiseSerial(funcs) {
    return funcs.reduce((p, f) => p.then(r => f().then(Array.prototype.concat.bind(r))),
      Promise.resolve([]));
  }

  static fetchContact(url, accessor) {
    return fetch(url, { credentials: 'same-origin' })
      .then(response => response.json()).then(json => json[accessor]);
  }

  async fetchOptions() {
    let accessor;

    if (this.contactType === 'provider_contacts') {
      accessor = 'provider_contact';
    } else {
      accessor = 'contact';
    }

    const urls = this.selectedValues.map(id => `/city/${this.contactType}/${id}.json`);
    const funcs = urls.map(url => () => ContactSearchController.fetchContact(url, accessor));

    return ContactSearchController.promiseSerial(funcs).then(result => result);
  }

  fetchData(query, callback) {
    this.query = query;
    if (!query.length || query.length < 2) return callback();
    return fetch(`/city/${this.contactType}.json?${encodeURIComponent('q[full_name_cont]')}=${encodeURIComponent(query)}`, { credentials: 'same-origin' })
      .then(response => response.json())
      .then((data) => {
        callback(data[this.contactType]);
      });
  }
}

export default ContactSearchController;
