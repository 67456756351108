import mapboxgl from 'mapbox-gl';

export default class MapboxMapAdapter {
  constructor() {
    // NOTE: Remember to change this token for staging/production use.
    mapboxgl.accessToken = 'pk.eyJ1Ijoic3dpZnRjb21wbHkiLCJhIjoiY2tua2I1eTVyMDNvODJucHJmZWt6ZnVsZyJ9.AyMir3yb-hIuSbrxON1HHg';

    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
    });

    this.markers = [];
  }

  addNavigationControls() {
    this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));
  }

  addMarker(data) {
    const marker = new mapboxgl.Marker().setLngLat([data.longitude, data.latitude]).addTo(this.map);

    this.markers.push(marker);

    return marker;
  }

  removeMarkers() {
    this.markers.forEach((marker) => {
      marker.remove();
    });

    this.markers = [];
  }

  onClick(fun) { this.map.on('click', fun); }

  setViewport(data) {
    if (data.length === 0) { return; }

    const fitBoundsData = MapboxMapAdapter.buildFitBoundsData(data);

    this.map.fitBounds(fitBoundsData, { padding: 20 });
  }

  static buildFitBoundsData(data) {
    const longitudes = data.map(el => el.longitude);
    const latitudes = data.map(el => el.latitude);

    const west = Math.min(...longitudes);
    const south = Math.min(...latitudes);
    const east = Math.max(...longitudes);
    const north = Math.max(...latitudes);

    return [[west, south], [east, north]];
  }
}
