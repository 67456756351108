import { Controller } from 'stimulus';
import mapboxgl from 'mapbox-gl';

class MapController extends Controller {
  static initializeMap() {
    mapboxgl.accessToken = 'pk.eyJ1Ijoic3dpZnRjb21wbHkiLCJhIjoiY2tua2I1eTVyMDNvODJucHJmZWt6ZnVsZyJ9.AyMir3yb-hIuSbrxON1HHg';
    MapController.getMapElement().innerHTML = '';

    const map = new mapboxgl.Map({
      container: MapController.mapElementId,
      style: 'mapbox://styles/mapbox/streets-v11',
    });

    map.on('click', (e) => {
      const coordinates = e.lngLat;
      const zoom = map.getZoom();
      MapController.saveMapCoordinatesState(coordinates, zoom);
      MapController.saveMapToggleState();
    });

    map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));

    return map;
  }

  static saveMapCoordinatesState(coordinates, zoom) {
    sessionStorage.setItem('mapLng', coordinates.lng);
    sessionStorage.setItem('mapLat', coordinates.lat);
    sessionStorage.setItem('mapZoom', zoom);
  }

  static removeMapCoordinatesState() {
    sessionStorage.removeItem('mapLng');
    sessionStorage.removeItem('mapLat');
    sessionStorage.removeItem('mapZoom');
  }

  static expandMapElement() {
    const mapElement = document.getElementById('map');

    mapElement.style.widht = '100%';
    mapElement.style.height = '680px';
  }

  static expandMapContainer() {
    const mapContainer = MapController.getMapContainer();

    mapContainer.classList.remove('collapse');
  }

  static getMapContainer() {
    return document.getElementById('collapseMap');
  }

  static getMapElement() {
    return document.getElementById(MapController.mapElementId);
  }

  static findMaxLongitude(data) {
    const longitudes = data.map(el => el.longitude);
    return Math.max(...longitudes);
  }

  static findMinLongitude(data) {
    const longitudes = data.map(el => el.longitude);
    return Math.min(...longitudes);
  }

  static findMaxLatitude(data) {
    const latitudes = data.map(el => el.latitude);
    return Math.max(...latitudes);
  }

  static findMinLatitude(data) {
    const latitudes = data.map(el => el.latitude);
    return Math.min(...latitudes);
  }

  static buildFitBoundsData(data) {
    const west = MapController.findMinLongitude(data);
    const south = MapController.findMinLatitude(data);
    const east = MapController.findMaxLongitude(data);
    const north = MapController.findMaxLatitude(data);

    return [[west, south], [east, north]];
  }

  static isMapOpen() {
    const mapContainer = MapController.getMapContainer();
    return !mapContainer.classList.contains('collapse');
  }

  static collapseMap() {
    const mapContainer = MapController.getMapContainer();

    mapContainer.classList.add('collapse');
  }

  static getInspectedOnLabel() {
    const mapElement = MapController.getMapElement();

    return mapElement.dataset.popupInspectedOnLabel;
  }

  static getInspectionStatusLabel() {
    const mapElement = MapController.getMapElement();

    return mapElement.dataset.popupInspectionStatusLabel;
  }

  static getDetailsLinkText() {
    const mapElement = MapController.getMapElement();

    return mapElement.dataset.popupDetailsLink;
  }

  static getGcdStatusLabel() {
    const mapElement = MapController.getMapElement();

    return mapElement.dataset.popupGcdStatusLabel;
  }

  static getGcdCapacityLabel() {
    const mapElement = MapController.getMapElement();

    return mapElement.dataset.popupGcdCapacityLabel;
  }

  static buildMapPopup(data) {
    const inspectedOnLabel = MapController.getInspectedOnLabel();
    const inspectedOn = (data.inspectedOn) ? `<div class="map-subtitle">${inspectedOnLabel}: ${data.inspectedOn}</div>` : '';

    const compliaceMarker = `<span class="${MapController.buildMarkerClass(data.complianceStatus)}"></span>`;

    const header = `<div class="modalMap-title"><strong class="map-title">${compliaceMarker}${data.name}</strong>${inspectedOn}</div>`;

    const gcdStatusLabel = MapController.getGcdStatusLabel();
    const gcdCapacityLabel = MapController.getGcdCapacityLabel();

    const greaseTrapsBlock = data.greaseTraps.reduce((result, greaseTrap) => {
      const greaseTrapColorClass = greaseTrap.statusColor ? `map-status__${greaseTrap.statusColor}` : '';
      const greaseTrapName = `<div class="map-status__title ${greaseTrapColorClass}">${greaseTrap.name}</div>`;
      const greaseTrapStatus = greaseTrap.status ? `<div class="map-status__name">${gcdStatusLabel}: ${greaseTrap.status}</div>` : '';
      const greaseTrapCapacity = `<div class="map-status__capacity">${gcdCapacityLabel}: ${greaseTrap.capacity}</div>`;

      return `${result}<div class="map-status">${greaseTrapName}${greaseTrapStatus}${greaseTrapCapacity}</div>`;
    }, '');

    const popupContent = `<div class="modalMap-content">${greaseTrapsBlock}</div>`;

    const detailsLinkText = MapController.getDetailsLinkText();

    const popupHtml = `${header} ${popupContent} <div class="buttons-section"><a href="${data.link}" class="btn btn-primary full-width-btn">${detailsLinkText}</a></div>`;

    return new mapboxgl.Popup().setHTML(popupHtml);
  }

  static buildMarkerClass(statusData) {
    if (MapController.isCompliantStatus(statusData)) { return 'marker__circle marker__green'; }
    if (MapController.isOverdueStatus(statusData)) { return 'marker__triangle marker__yellow'; }
    if (MapController.isNonCompliantStatus(statusData)) { return 'marker__square marker__red'; }
    if (MapController.isNoPumpOutsStatus(statusData)) { return 'marker__cross marker__redBorder'; }
    if (MapController.isNoDevicesStatus(statusData)) { return 'marker__dash marker__blue'; }

    return 'marker__cross marker__redBorder';
  }

  static buildMapMarker(data) {
    const markerHtml = document.createElement('div');

    markerHtml.className = MapController.buildMarkerClass(data.complianceStatus);

    return { element: new mapboxgl.Marker(markerHtml) };
  }

  static isCompliantStatus(statusData) { return statusData === 'compliant'; }

  static isOverdueStatus(statusData) { return statusData === 'overdue'; }

  static isNonCompliantStatus(statusData) { return statusData === 'non_compliant'; }

  static isNoPumpOutsStatus(statusData) { return statusData === 'never_pumped'; }

  static isNoDevicesStatus(statusData) { return statusData === 'no_devices'; }

  static async fetchLocationsData() {
    const searchForm = document.getElementsByTagName('form')[0];
    const searchFormData = new FormData(searchForm);
    const urlSearchParams = new URLSearchParams(searchFormData);

    return fetch(
      '/city/establishments/map-results',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: urlSearchParams,
      },
    ).then(response => response.json());
  }

  async openMap() {
    MapController.expandMapContainer();

    if (!this.map) {
      MapController.expandMapElement();

      this.map = MapController.initializeMap();
    }

    this.updateMap();
  }

  toggleButtonTextToClosed() {
    const button = this.toggleMapButtonTarget;
    const mapClosedText = button.dataset.closed;

    button.innerText = mapClosedText;
  }

  toggleButtonTextToOpened() {
    const button = this.toggleMapButtonTarget;
    const mapOpenedText = button.dataset.open;

    button.innerText = mapOpenedText;
  }

  toggleMap() {
    const isMapOpen = MapController.isMapOpen();

    if (isMapOpen) {
      MapController.collapseMap();
      this.toggleButtonTextToClosed();
    }
    if (!isMapOpen) {
      this.openMap();
      this.toggleButtonTextToOpened();
    }
  }

  static saveMapToggleState() {
    sessionStorage.setItem('openMap', 'true');
  }

  static removeMapToggleState() {
    sessionStorage.removeItem('openMap');
  }

  updateViewport(data) {
    if (data.length === 0) { return; }

    const fitBoundsData = MapController.buildFitBoundsData(data);

    this.map.fitBounds(fitBoundsData, { padding: 20 });
  }

  clearMarkers() {
    this.markers.forEach(marker => marker.element.remove());
    this.markers = [];
  }

  updateMarkers(data) {
    this.clearMarkers();

    data.forEach((loc) => {
      const newMarker = MapController.buildMapMarker(loc);
      const markerPopup = MapController.buildMapPopup(loc);

      newMarker.element.setPopup(markerPopup);
      newMarker.element.setLngLat([loc.longitude, loc.latitude]).addTo(this.map);

      this.markers.push(newMarker);
    });
  }

  static updateMapCounters(data) {
    const compliant = data.filter(d => MapController.isCompliantStatus(d.complianceStatus));
    const overdue = data.filter(d => MapController.isOverdueStatus(d.complianceStatus));
    const nonCompliant = data.filter(d => MapController.isNonCompliantStatus(d.complianceStatus));
    const noPumpOuts = data.filter(d => MapController.isNoPumpOutsStatus(d.complianceStatus));
    const noDevices = data.filter(d => MapController.isNoDevicesStatus(d.complianceStatus));

    const getFirstElementByClassName = (className) => {
      const elements = document.getElementsByClassName(className);

      return elements[0];
    };

    const compliantCounter = getFirstElementByClassName('js-compliant-count');
    const overdueCounter = getFirstElementByClassName('js-overdue-count');
    const nonCompliantCounter = getFirstElementByClassName('js-non-compliant-count');
    const noPumpOutsCounter = getFirstElementByClassName('js-no-pump-outs-count');
    const noDevicesCounter = getFirstElementByClassName('js-no-devices-count');

    compliantCounter.innerText = compliant.length;
    overdueCounter.innerText = overdue.length;
    nonCompliantCounter.innerText = nonCompliant.length;
    noPumpOutsCounter.innerText = noPumpOuts.length;
    noDevicesCounter.innerText = noDevices.length;
  }

  async updateMap() {
    const { data } = await MapController.fetchLocationsData();

    this.updateMarkers(data);
    if (MapController.isMapCoordinatesSaved()) {
      this.loadMapCoordinates();
      MapController.removeMapState();
    } else {
      this.updateViewport(data);
    }
    MapController.updateMapCounters(data);
  }

  static removeMapState() {
    if (MapController.isTubrolinksPreviewPage()) { return; }

    MapController.removeMapToggleState();
    MapController.removeMapCoordinatesState();
  }

  static isTubrolinksPreviewPage() {
    return document.documentElement.hasAttribute('data-turbolinks-preview');
  }

  static isMapCoordinatesSaved() {
    const zoom = sessionStorage.getItem('mapZoom');
    const lng = sessionStorage.getItem('mapLng');
    const lat = sessionStorage.getItem('mapLat');

    return !!zoom && !!lng && !!lat;
  }

  loadMapCoordinates() {
    this.map.setZoom(sessionStorage.getItem('mapZoom'));
    const lng = sessionStorage.getItem('mapLng');
    const lat = sessionStorage.getItem('mapLat');
    this.map.setCenter([lng, lat]);
  }

  connect() {
    this.markers = [];

    this.resultsTableTarget.addEventListener('update', () => {
      if (MapController.isMapOpen()) { this.updateMap(); }
    });

    if (MapController.isMapShouldBeOpen()) {
      this.openMap();
      this.toggleButtonTextToOpened();
    }
  }

  static isMapShouldBeOpen() {
    return sessionStorage.getItem('openMap') === 'true';
  }
}

MapController.targets = ['resultsTable', 'toggleMapButton'];
MapController.mapElementId = 'map';

export default MapController;
