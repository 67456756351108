import mapboxgl from 'mapbox-gl';
import { Controller } from 'stimulus';
import MapboxMapAdapter from '../adapters/mapbox_map_adapter';

class EditCoordinatesController extends Controller {
  static mapContainerTemplate() {
    return '<div id="map" style="width: 100%; height: 500px;"></div>';
  }

  static buildMarker(data) {
    return new mapboxgl.Marker().setLngLat([data.lng, data.lat]);
  }

  static showModal() {
    $('.js-new-form-modal').modal('show');
  }

  static hideModal() {
    $('.js-new-form-modal').modal('hide');
  }

  static searchInputTemplate() {
    return '<select name="location" class="js-location-search"></select>';
  }

  connect() {
    this.coordinates = this.fetchCurrentCoordinates();
  }

  get isMapInitialized() {
    return !!this.map;
  }

  get isCurrentCoordinatesPresent() {
    return !!this.coordinates.latitude && !!this.coordinates.longitude;
  }

  openMapModal(event) {
    event.preventDefault();

    // NOTE: Timeout here is needed to properly render map in modal.
    setTimeout(() => {
      if (!this.isMapInitialized) {
        this.setupModal();
        this.setupMap();
        this.setupMapEvents();

        if (this.isCurrentCoordinatesPresent) {
          this.setupCurrentMarker();
        }
      }
    }, 200);

    EditCoordinatesController.showModal();
  }

  setupModal() {
    $('.js-modal-title').html(this.fetchModalTitle());

    const searchInput = EditCoordinatesController.searchInputTemplate();
    const mapContainer = EditCoordinatesController.mapContainerTemplate();
    const cancelButton = this.cancelButtonTemplate();
    const setButton = this.setButtonTemplate();

    $('.js-modal-body').html(`${searchInput}${mapContainer}${cancelButton}${setButton}`);

    this.selectizeSearchField();
  }

  selectizeSearchField() {
    $('.js-location-search').selectize(
      {
        maxOptions: 5,
        valueField: 'latLon',
        labelField: 'name',
        searchField: 'name',
        load: (query, callback) => {
          // NOTE: I used debouncing here because geocoder API is free and restricted to 1 req/sec.
          //   You can change/remove it if geocoder API changed.
          clearTimeout(this.timer);

          this.timer = setTimeout(() => {
            fetch(`/city/establishments/find-location?location_name=${encodeURI(query)}`)
              .then(response => response.json())
              .then((data) => { callback(data); });
          }, 1000);
        },
        onItemAdd: (value) => {
          const [latitude, longitude] = value.split(',');

          this.map.setViewport([{ latitude, longitude }]);
        },
      },
    );
  }

  cancelButtonTemplate() {
    return `<button name="button" type="button" class="btn btn-light mr-2 mt-1" data-dismiss="modal">${this.fetchCancelButtonText()}</button>`;
  }

  setButtonTemplate() {
    return `<button class="btn btn-success mt-1 float-right js-set-coordinates" data-action="edit-coordinates#setNewCoordinates">${this.fetchModalSetButtonText()}</button>`;
  }

  fetchCurrentCoordinates() {
    return { longitude: this.longitudeInputTarget.value, latitude: this.latitudeInputTarget.value };
  }

  fetchModalTitle() {
    return this.openMapModalButtonTarget.dataset.modalTitle;
  }

  fetchCancelButtonText() {
    return this.openMapModalButtonTarget.dataset.modalCancelButtonText;
  }

  fetchModalSetButtonText() {
    return this.openMapModalButtonTarget.dataset.modalSetButtonText;
  }

  setupMap() {
    this.map = new MapboxMapAdapter();
    this.map.addNavigationControls();
  }

  setupMapEvents() {
    this.addSetCoordinatesEvent();

    this.map.onClick((click) => {
      this.map.removeMarkers();

      const { lat: latitude, lng: longitude } = click.lngLat;

      this.map.addMarker({ latitude, longitude });
      this.newLatitude = latitude;
      this.newLongitude = longitude;
    });
  }

  addSetCoordinatesEvent() {
    const setButtonNode = document.getElementsByClassName('js-set-coordinates')[0];

    setButtonNode.addEventListener('click', () => {
      this.latitudeInputTarget.value = this.newLatitude;
      this.longitudeInputTarget.value = this.newLongitude;

      EditCoordinatesController.hideModal();
    });
  }

  setupCurrentMarker() {
    this.map.addMarker(this.coordinates);
    this.map.setViewport([this.coordinates]);
  }
}

EditCoordinatesController.targets = ['openMapModalButton', 'latitudeInput', 'longitudeInput'];

export default EditCoordinatesController;
