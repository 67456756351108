import ApplicationController from '../support/application_controller';

/**
 * Control question conditions displaying
 */
class AnswersController extends ApplicationController {
  initialize() {
    this.key = this.questionTarget.dataset.key;
    this.hideOrShow();
  }

  answer() {
    Array.from(this.questionTarget.parentElement.children).forEach((question) => {
      question.dispatchEvent(new Event('newAnswer'));
    });
  }

  onNewAnswer() { this.hideOrShow(); }

  hideOrShow() {
    if (this.isNotConditional()) return;

    if (this.isConditionMet()) {
      if (this.typeValue() === 'show') this.show();
      if (this.typeValue() === 'hide') this.hide();
    } else {
      if (this.typeValue() === 'show') this.hide();
      if (this.typeValue() === 'hide') this.show();
    }
  }

  isNotConditional() {
    return this.fetchInputValueByKey(this.key) !== '1';
  }

  fetchInputValueByKey(key) {
    return this.questionTarget.querySelector(`#answers_${key}_conditional`).value;
  }

  isConditionMet() {
    if (this.inputAnswers().length > 0) {
      return this.inputAnswers().filter(answer => answer.value === this.answerValue())
        .map(answer => answer.checked)[0];
    }
    return this.selectAnswer().filter(answer => answer.value === this.answerValue()).length > 0 && this.conditionalQuestion().style.display !== 'none';
  }

  inputAnswers() {
    return Array.from(this.conditionalQuestion().querySelectorAll('fieldset input'));
  }

  selectAnswer() {
    return Array.from(this.conditionalQuestion().querySelectorAll('select'));
  }

  conditionalQuestion() {
    return this.questionTarget.parentElement.querySelector(`[data-key='${this.questionValue()}']`);
  }

  typeValue() {
    return this.questionTarget.querySelector(`#answers_${this.key}_conditional-type`).value;
  }

  questionValue() {
    return this.questionTarget.querySelector(`#answers_${this.key}_conditional-question`).value;
  }

  answerValue() {
    return this.questionTarget.querySelector(`#answers_${this.key}_conditional-answer`).value;
  }

  hide() {
    const answerInput = this.fetchAnswerInputByKey(this.key);
    answerInput.value = '';

    this.questionTarget.style.display = 'none';
  }

  fetchAnswerInputByKey(key) {
    return this.questionTarget.querySelector(`#answers_${key}_answer`);
  }

  show() {
    this.questionTarget.style.display = 'block';
  }
}

AnswersController.targets = ['question'];

export default AnswersController;
